<template>
  <div class="home">
    <div class="header">
      <div class="title">USTC FAQ</div>
      <form class="filter">
        <select v-model="campus">
          <option>不限校区</option>
          <option>高新区</option>
          <option>先研院</option>
          <option>东区</option>
          <option>中区</option>
          <option>西区</option>
        </select>
        <select v-model="group">
          <option>不限</option>
          <option>学习</option>
          <option>生活</option>
        </select>
        <input id="search" v-model="search" placeholder="在此搜索问题，关键词用空格分开">
      </form>
      <div>{{ filterTintString() }}</div>
    </div>
    <div class="main">
      <QA v-for="faq in faqFilter(campus,group,search)" :key="faq.q" v-bind="faq"></QA>
    </div>
    <div class="footer">
      <div>USTC FAQ faq.xzkd.online</div>
      <div>By 学在科大，部分FAQ数据由Silence::提供</div>
    </div>
  </div>
</template>

<script setup>
import QA from '@/components/QA'
import faqs from "@/data/faqs";
import {ref} from "vue";

const campus = ref('不限校区')
const group = ref('不限')
const search = ref('')

function filterTintString() {
  if (campus.value === '不限校区' && group.value === '不限' && search.value === '') {
    return ''
  }
  let string = '筛选';
  if (campus.value !== '不限校区') {
    string += '适用于' + campus.value
  }
  if (group.value !== '不限') {
    if (campus.value !== '不限校区') {
      string += '且'
    }
    string += '分类为' + group.value
  }
  if (search.value !== '') {
    if (campus.value !== '不限校区' || group.value !== '不限') {
      string += '且'
    }
    string += '问题中包含关键字'
    let theKeywords = keywords(search.value)
    for (let i = 0; i < theKeywords.length; i++) {
      string += '"' + theKeywords[i] + '"'
      if (i !== theKeywords.length - 1) {
        string += '或'
      }
    }
  }
  string += '的问答'
  return string
}

function keywords(search) {
  return search.split(' ').filter(item => item !== '')
}

function faqFilter(campus, group, search) {
  function containsKeywords(string, keywords) {
    for (let i = 0; i < keywords.length; i++) {
      if (string.indexOf(keywords[i]) !== -1) {
        return true
      }
    }
    return false
  }

  return faqs.filter(item => {
    return (campus === '不限校区' || item.c.includes(campus)) && (group === '不限' || item.g === group) && (search === '' || containsKeywords(item.q, keywords(search)))
  })
}
</script>

<style scoped>
.home {
  height: 100%;
  background: #E6F7FF;
}

.header {
  height: 130px;
}

.main {
  height: calc(100% - 130px - 60px);
  overflow: auto;
  margin: 0 32px;
}

.footer {
  height: 60px;
}

.title {
  color: #0094ff;
  font-weight: bold;
  font-size: 48px;
}
.filter{
  background: #B3E7FE;
  padding: 8px;
}

select{
  padding: 4px;
}

#search{
  padding: 4px;
  width: 60vw;
}



</style>