let faqs = [
  /*
  以下是模板
  {
    'q':'问题？',
    'a':'答案',
    'c':['校区','校区'],
    'g':'分类'
  }
  */
  {
    'q': 'GT-A楼在哪？',
    'a': '图书馆玻璃盒子',
    'c': ['高新区'],
    'g': '学习'
  },
  {
    'q': 'GT-B楼在哪？',
    'a': '图书馆玻璃盒子东北侧裙楼',
    'c': ['高新区'],
    'g': '学习'
  },
  {
    'q': 'GT-C楼在哪？',
    'a': '图书馆玻璃盒子东南侧裙楼',
    'c': ['高新区'],
    'g': '学习'
  },
  {
    'q': 'GH楼在哪？',
    'a': '南门旁师生活动中心',
    'c': ['高新区'],
    'g': '学习'
  },
  {
    'q': 'GX楼在哪？',
    'a': '信智大楼',
    'c': ['高新区'],
    'g': '学习'
  },
  {
    'q': '未来中心在哪？',
    'a': '地图找先研院x形状的楼',
    'c': ['高新区'],
    'g': '学习'
  },
  {
    'q': '电动车在哪充电？',
    'a': '先研院地下车库或中国声谷地下车库',
    'c': ['高新区'],
    'g': '生活'
  },
  {
    'q': '游泳馆在哪？',
    'a': '高新校区没有游泳馆，中区有',
    'c': ['高新区', '中区'],
    'g': '生活'
  },
  {
    'q': '中区游泳馆怎么预约？',
    'a': '中国科大保卫与校园管理处微信公众号'
    , 'c': ['中区'],
    'g': '生活'
  },
  {
    'q': '校医室在哪？',
    'a': '图书馆北侧行政办公楼（原人才公寓）',
    'c': ['高新区'],
    'g': '生活'
  },
  {
    'q': '高新校医院能验光吗？',
    'a': '不能',
    'c': ['高新区'],
    'g': '生活'
  },
  {
    'q': '高新校医院能洗牙吗？',
    'a': '不能',
    'c': ['高新区'],
    'g': '生活'
  },
  {
    'q': '去东区校医院需要在网上预约吗？',
    'a': '不用，直接线下去，网上预约是给校外人员的',
    'c': ['高新区'],
    'g': '生活'
  },
  {
    'q': '为什么网上预约的按钮点不动？',
    'a': '网上预约是给校外人员的，他们得提前几周卷',
    'c': ['高新区'],
    'g': '生活'
  },
  {
    'q': '东区校医院能洗牙吗？',
    'a': '能',
    'c': ['高新区'],
    'g': '生活'
  },
  {
    'q': '洗牙能报销吗？',
    'a': '不能',
    'c': ['高新区'],
    'g': '生活'
  },
  {
    'q': '医保怎么报销？',
    'a': '到中科大医保系统的Q&A板块搜索',
    'c': ['高新区'],
    'g': '生活'
  },
  {
    'q': 'ATM机在哪？',
    'a': '师生活动中心一楼'
    , 'c': ['高新区'],
    'g': '生活'
  },
  {
    'q': '电信营业厅在哪？',
    'a': '师生活动中心',
    'c': ['高新区'],
    'g': '生活'
  },
  {
    'q': '联通营业厅在哪？',
    'a': '师生活动中心',
    'c': ['高新区'],
    'g': '生活'
  },
  {
    'q': '移动营业厅在哪？',
    'a': '师生活动中心',
    'c': ['高新区'],
    'g': '生活'
  },
  {
    'q': '理发店在哪？',
    'a': '玉兔餐厅二楼南侧'
    , 'c': ['高新区'],
    'g': '生活'
  },
  {
    'q': '先研院理发店在哪？',
    'a': '先研院人才公寓2号楼（最西南侧的）一楼',
    'c': ['先研院'],
    'g': '生活'
  },
  {
    'q': '在哪能打印？',
    'a': '师生活动中心一楼/图书馆一楼/玉兔餐厅二楼南侧'
    , 'c': ['高新区'],
    'g': '生活'
  },
  {
    'q': '在哪打印研究生/本科生的成绩单/在读证明？',
    'a': 'GT-B一楼',
    'c': ['高新区'],
    'g': '生活'
  },
  {
    'q': '一卡通在哪补办？',
    'a': '南门师生活动中心',
    'c': ['高新区'],
    'g': '生活'
  },
  {
    'q': '补办需要带什么东西？',
    'a': '身份证',
    'c': ['高新区'],
    'g': '生活'
  },
  {
    'q': '高新校区有一卡通自助补卡机吗？',
    'a': '没有',
    'c': ['高新区'],
    'g': '生活'
  },
  {
    'q': '健身房开到几点？',
    'a': '晚上十点半',
    'c': ['高新区'],
    'g': '生活'
  },
  {
    'q': '快递点开到几点？',
    'a': '晚上九点',
    'c': ['高新区'],
    'g': '生活'
  },
  {
    'q': '在哪寄快递？',
    'a': '快递点',
    'c': ['高新区'],
    'g': '生活'
  },
  {
    'q': '校车能开进西区吗？',
    'a': '能',
    'c': ['高新区'],
    'g': '生活'
  },
  {
    'q': '东门开了吗？',
    'a': '没开',
    'c': ['高新区'],
    'g': '生活'
  },
  {
    'q': '西门开了吗？',
    'a': '开了',
    'c': ['高新区'],
    'g': '生活'
  },
  {
    'q': '东门能放外卖吗？',
    'a': '不能',
    'c': ['高新区'],
    'g': '生活'
  },
  {
    'q': '西门能放外卖吗？',
    'a': '能',
    'c': ['高新区'],
    'g': '生活'
  },
  {
    'q': '南门能放外卖吗？',
    'a': '能',
    'c': ['高新区'],
    'g': '生活'
  },
  {
    'q': '北门能放外卖吗？',
    'a': '不能',
    'c': ['高新区'],
    'g': '生活'
  },
  {
    'q': '校车时间表是什么？',
    'a': '学校主页-公共服务，或者参见bus.xzkd.online',
    'c': ['高新区'],
    'g': '生活'
  },
  {
    'q': '东区在哪坐校车？',
    'a': '一教西侧候车棚',
    'c': ['高新区'],
    'g': '生活'
  },
  {
    'q': '西区在哪坐校车？',
    'a': '图书馆西南侧候车棚',
    'c': ['高新区'],
    'g': '生活'
  },
  {
    'q': '高新区在哪坐校车？',
    'a': '体育馆南侧桥洞',
    'c': ['高新区'],
    'g': '生活'
  },
  {
    'q': '校车能开进高新区吗？',
    'a': '能',
    'c': ['高新区'],
    'g': '生活'
  },
  {
    'q': '去先研院用报备吗？',
    'a': '用',
    'c': ['高新区'],
    'g': '生活'
  },
  {
    'q': '高新区宿舍电源最大功率多少？',
    'a': '3200W',
    'c': ['高新区'],
    'g': '生活'
  },
  {
    'q': '能用吹风机吗？',
    'a': '不能',
    'c': ['高新区', '东区', '西区', '中区'],
    'g': '生活'
  },
  {
    'q': '能用小锅吗？',
    'a': '不能',
    'c': ['高新区', '东区', '西区', '中区'],
    'g': '生活'
  },
  {
    'q': '能用电脑主机吗？',
    'a': '能',
    'c': ['高新区', '东区', '西区', '中区'],
    'g': '生活'
  },
  {
    'q': '空调怎么充电？',
    'a': '科大基建微信公众号，缴费前先看保修缴费-使用指南',
    'c': ['高新区', '东区', '西区', '中区'],
    'g': '生活'
  },
  {
    'q': '电费多少钱一度？',
    'a': '0.55',
    'c': ['高新区', '东区', '西区', '中区'],
    'g': '生活'
  },
  {
    'q': '校外哪能做核酸？',
    'a': '安医高新',
    'c': ['高新区'],
    'g': '生活'
  },
  {
    'q': '高新区有没有24小时核酸？',
    'a': '后半夜没有，安医高新最晚只到11点',
    'c': ['高新区'],
    'g': '生活'
  },
  {
    'q': '高新区/先研院附近哪能修手机？',
    'a': '祥源城',
    'c': ['高新区', '先研院'],
    'g': '生活'
  },
  {
    'q': '高新区/先研院附近哪能修电脑？',
    'a': '祥源城',
    'c': ['高新区', '先研院'],
    'g': '生活'
  },
  {
    'q': '高新区/先研院附近哪能配钥匙？',
    'a': '祥源城',
    'c': ['高新区', '先研院'],
    'g': '生活'
  },
  {
    'q': '一卡通中心几点开门？',
    'a': '八点',
    'c': ['高新区'],
    'g': '生活'
  },
  {
    'q': '一卡通中心几点关门？',
    'a': '晚六点',
    'c': ['高新区'],
    'g': '生活'
  },
  {
    'q': '高新区健身房几点开门？',
    'a': '八点',
    'c': ['高新区'],
    'g': '生活'
  },
  {
    'q': '高新区健身房几点关门？',
    'a': '晚十点半',
    'c': ['高新区'],
    'g': '生活'
  },
  {
    'q': '高新区超市几点关门？',
    'a': '晚十一点',
    'c': ['高新区'],
    'g': '生活'
  },
  {
    'q': '高新区食堂夜宵窗口几点关门？',
    'a': '晚十一点',
    'c': ['高新区'],
    'g': '生活'
  },
  {
    'q': '高新区快递点几点关门？',
    'a': '晚九点',
    'c': ['高新区'],
    'g': '生活'
  },
  {
    'q': '核酸几点结束？',
    'a': '学校主页-通知公告-管理类通知，目前为下午3点',
    'c': ['高新区'],
    'g': '生活'
  },
  {
    'q': '高新区校医院（医务室）几点开门？',
    'a': '八点',
    'c': ['高新区'],
    'g': '生活'
  },
  {
    'q': '高新区校医院（医务室）几点关门？',
    'a': '晚五点',
    'c': ['高新区'],
    'g': '生活'
  },
  {
    'q': '东区校医院几点开门？',
    'a': '校医院官网排班表',
    'c': ['高新区'],
    'g': '生活'
  },
  {
    'q': '东区校医院几点关门？',
    'a': '校医院官网排班表',
    'c': ['高新区'],
    'g': '生活'
  }
]

export default faqs